import React, { useState, useEffect } from 'react';
import './ScrollToTop.css';

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);
  
    // Check if button should be visible
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 2000) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
  
    // Scroll to top smoothly
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    return <div className="scroll-to-top">
        {isVisible && (
          <a onClick={scrollToTop}>
            Scroll to top
          </a>
        )}
      </div>;
  }
  
  export default ScrollToTop;