import logo from '../assets/logo.svg';

const Footer = () => {
    return <>
        <footer>
            <div className="footer-container">
                <img src={logo} alt="Actual Intelligence logo" className="footer__logo"/>
                <p>Copyright © {(new Date()).getFullYear()} <br/>Actual Intelligence, Inc. <br/>All rights reserved.</p>
            </div>
        </footer>
    </>
}

export default Footer;