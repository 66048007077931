import Section from './Section';
import Netflix from '../../assets/companies/netflix.png';
import WSJ from '../../assets/companies/wsj.png';
import NBC from '../../assets/companies/nbc.png';
import Vox from '../../assets/companies/voxmedia.png';
import NYMag from '../../assets/companies/nymag.png';
import Haven from '../../assets/companies/haven.png';
import ScrollToTop from '../ScrollToTop';

const Companies = props => {
    return <Section classLabel="companies">
        <h2>Professional experience</h2>
        <div className="companies container__flex">
            <img src={WSJ} alt="The Wall Street Journal" />
            <img src={Vox} alt="Vox Media" />
            <img src={NYMag} alt="New York Magazine" />
            {/* <img src={Netflix} alt="Netflix"/> */}
            {/* <img src={NBC} alt="NBC"/> */}
            <img src={Haven} alt="Haven Technologies" />
        </div>
        <ScrollToTop />
    </Section>
}

export default Companies;