import { useRef } from 'react';

// styles
import './App.scss';

// components
import SplashLanding from './components/HomeSections/SplashLanding';
import Spotlight from './components/HomeSections/Spotlight';
import GlobalExpertise from './components/HomeSections/GlobalExpertise';
import ImageGenerator from './components/HomeSections/ImageGenerator';
import LandingContactForm from './components/HomeSections/LandingContactForm';
import HumanElement from './components/HomeSections/HumanElement';
import WhoWeAre from './components/HomeSections/WhoWeAre';
import Companies from './components/HomeSections/Companies';
import Footer from './components/Footer';

function App() {
  const contactRef = useRef(null);
  const goToContact = ()=>{
    if (contactRef.current) {
      contactRef.current.scrollIntoView({
          behavior: 'smooth'
      });
    }
  };

  return (
    <div className="App">
      <main>
        <SplashLanding />
        <Spotlight classLabel="value-proposition">
          <h3><span className="gradient-text">Actual Intelligence</span> uses human outsourcing <br/>to build, train, and provide quality control <br/>to AI products.<br/>
          <button onClick={goToContact}><span>Start your AI evolution</span></button></h3>
          
        </Spotlight>
        <GlobalExpertise />
        <HumanElement />
        <Spotlight classLabel="summary">
          <div className="summary--container">
            <h3><span className="gradient-text">Actual Intelligence</span> combines human ingenuity <br/>with AI tools essential for AI success.<br/>
            <button onClick={goToContact}><span>Experiment with human-powered AI</span></button>
            </h3>
          </div>
        </Spotlight>
        <ImageGenerator />
        <div ref={contactRef}>
          <LandingContactForm/>
        </div>
        <WhoWeAre />
        <Companies />
      </main>
      <Footer />
    </div>
  );
}

export default App;
