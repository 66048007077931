import Section from './Section';
import Bianca from '../../assets/profile-photo-bianca.jpg';
import Evan from '../../assets/profile-photo-evan.jpg';
import Les from '../../assets/profile-photo-les.jpg';
import Liz from '../../assets/profile-photo-liz.jpg';

const WhoWeAre = props => {
    return <Section classLabel="who-we-are">
        <h2>Our team</h2>
        <div className="profile-photo-container">
            {/* <div>
                <img src={Bianca} alt="profile photo" />
                <h3>Bianca Consunji</h3>
                <p>Head of Operations</p>
            </div> */}
            <div>
                <img src={Evan} alt="profile photo" />
                <h3>Evan Engel</h3>
                <p>Head of Operations</p>
            </div>
            <div>
                <img src={Les} alt="profile photo" />
                <h3>Les Echem</h3>
                <p>Head of Product</p>
            </div>
            <div>
                <img src={Liz} alt="profile photo" />
                <h3>Licelle Cobrador</h3>
                <p>Legal Counsel</p>
            </div>
        </div>
        <div className="experience-texts container__flex">
            <p className="experience-summary">Our team of editorial, tech, and legal experts are at the forefront of AI-powered work. Reach out for a consultation so we can make AI a powerful tool in your business.</p>
            <ul className="experience-bullets">
                <li>Experience in testing and implementing innovation solutions for Fortune 500 companies</li>
                <li>Engineering expertise for highly secure, scalable, and maintainable applications and AI workflows</li>
                <li>Deep knowledge of intellectual property laws and risk mitigation</li>
                <li>Extensive managerial experience working in matrixed teams</li>
                <li>A vast global consultation network of tech and media experts</li>
            </ul>
        </div>
    </Section>
}

export default WhoWeAre;