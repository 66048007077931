import Section from './Section';
import Filipina from '../../assets/filipina.jpg';

const HumanElement = props => <Section classLabel="human-element">
    <h2>AI tools, human hands</h2>
    <p>
        Great AI needs two things: data and people. Your company has the data; our analysts will get it ready for the future. With carefully selected and annotated inputs, we'll train an AI model that responds to your company's needs while keeping your data private and secure.
        <br/>&nbsp;<br/>
        And once your AI is up and running, our expert-level quality control keeps a human in the loop to ensure quality and avoid errors— that’s how our AI is perfected by people.
    </p>
    <div className="container__flex">
        <img src={Filipina} />
        <div>
            <h3>We offer:</h3>
            <ul>
                <li>Data annotation</li>
                <li>Asset generation at scale (including photos, videos, art, text and audio)</li>
                <li>Asset quality assurance</li>
                <li>Fact-checking and correction</li>
                <li>Reviewing for cultural insensitivities and errors</li>
                <li>Brand risk mitigation</li>
                <li>Asset bias prevention</li>
            </ul>
        </div>
    </div>
</Section>

export default HumanElement;