import Section from './Section';
import img1 from '../../assets/img1.jpg';
import useCaseImage1 from '../../assets/use-case-1.jpg';
import useCaseImage2 from '../../assets/use-case-2.jpg';
import useCaseImage3 from '../../assets/use-case-3.jpg';
import productIcon1 from '../../assets/product-icon-1.png';
import productIcon2 from '../../assets/product-icon-2.png';
import productIcon3 from '../../assets/product-icon-3.png';
import { useState, useRef } from 'react';

const GlobalExpertise = props => {
    const highlightedCaseRef = useRef(null);
    const productsRef = useRef(null);
    const caseAssets = {
        assetGenQA: {
            caseTitle: 'Asset generation & quality assurance',
            caseText: `With Generative AI, brands can create thousands of image or text assets more efficiently than ever. But with more data comes more opportunities for errors. Inspecting AI output is tedious and time-consuming; for many companies, the cost of reviewing thousands of assets with in-house employees offsets the ease of creation. But by leveraging our network of offshore content editors, we can review AI-generated assets for hallucinations, cultural insensitivities, and other issues while keeping costs down for your business. With human-supervised AI, your brand can create content at scale with the assurance that only real people can provide.`,
            casePhoto: useCaseImage1
        },
        virtualBot: {
            caseTitle: 'Customer service & virtual assistant',
            caseText: `Chatbots are great — until they say something unscripted. As Generative AI has become more widespread, so have the threats against it: hackers and unethical users will try to jailbreak, hypnotize, or confuse AI Large Language Models. That's why keeping a human quality control layer is critical for the reputation of your business. When a chat goes wrong, AI can flag our team of quality control specialists to step in. That way, your customers — and your AI model — get the attention they need.`,
            casePhoto: useCaseImage2
        },
        dataManagement: {
            caseTitle: 'Data managment & entry',
            caseText: `Generative AI is only as good as the data it's trained on, and that can be a problem for companies who want AI tools: very often, data can be scattered, incomplete or siloed. Gathering that data and training an AI model can be laborious and risky: no one wants to see their private data end up on the open web, vulnerable to malicious parties. Our data teams are ready to get your company's information into an AI model that runs on private servers: safe, secure, and effective.`,
            casePhoto: useCaseImage3
        },
    }

    const [isUseCaseOpen, setIsUseCaseOpen] = useState(false);
    const [caseTitle, setCaseTitle] = useState(null);
    const [caseText, setCaseText] = useState(null);
    const [casePhoto, setCasePhoto] = useState(null);

    const setHighlightedCase = (caseName) => {
        const { caseTitle, caseText, casePhoto } = caseAssets[caseName];
        setIsUseCaseOpen(true);
        setCaseTitle(caseTitle);
        setCaseText(caseText);
        setCasePhoto(casePhoto);
        if (highlightedCaseRef.current) {
            highlightedCaseRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    const closeUseCaseHighlight = () => {
        setIsUseCaseOpen(false);
        if (productsRef.current) {
            productsRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    return <Section classLabel="global-expertise section__dark">
        <div className="container__flex">
            <h2>AI for every <br />business need</h2>
            <p className="expertise-copy">We build tools and workflows for companies at every stage of AI adoption from inception to deployment. With staff located around the world, we're able to execute every task in the AI chain.</p>
        </div>
        <div className="products-section" ref={productsRef}>
            <div className="container__flex container__centered">
                <div className="product-column">
                    <h3>Asset generation &#38; <br />quality assurance</h3>
                    <img src={productIcon1} className="product-icon" />
                    <p>Generate image and text assets at scale. And with a human-in-the-loop, brands can be sure that their AI-generated content is top quality.</p>
                    <a onClick={() => setHighlightedCase('assetGenQA')}>Explore use case</a>
                </div>
                <div className="product-column">
                    <h3>Customer service &#38; <br />virtual assitant</h3>
                    <img src={productIcon2} className="product-icon" />
                    <p>Chatbots can handle millions of customer inquiries at once. Human supervision ensures their quality and brand safety.</p>
                    <a onClick={() => setHighlightedCase('virtualBot')}>Explore use case</a>
                </div>
                <div className="product-column">
                    <h3>Data management <br />&#38; entry</h3>
                    <img src={productIcon3} className="product-icon" />
                    <p>Build and train an AI model with your company's proprietary data without exposing it to the rest of the world.</p>
                    <a onClick={() => setHighlightedCase('dataManagement')}>Explore use case</a>
                </div>
            </div>
            <div className={`highlighted-use-case ${isUseCaseOpen ? 'show-highlighted' : ''}`} ref={highlightedCaseRef}>
                <div className="highlighted-use-case__container">
                    <img src={casePhoto} alt="AI Use case example photo" />
                    <div>
                        <h3>
                            <span className="use-case-title">USE CASE</span>
                            <span className="use-case-example">{caseTitle}</span>
                        </h3>
                        <p>{caseText}</p>
                        <a onClick={closeUseCaseHighlight}>Close use case</a>
                    </div>
                </div>
            </div>
        </div>

    </Section>
}

export default GlobalExpertise;