import logo from '../../assets/logo.svg';
import splashPoster from '../../assets/background-top.jpg';
import splashPosterMobile from '../../assets/background-top-mobile.jpg';

const SplashLanding = props => {
    return <div className="section__splash-landing">
        <h1 className="seo-title">Actual Intelligence</h1>
        <div className="banner-image-container">
            <img className="splash-poster" src={splashPoster} alt="splash image" />
            <img className="splash-poster-mobile" src={splashPosterMobile} alt="splash image" />
        </div>
        <div className="logo-tagline-container">
            <img src={logo} alt="Actual Intelligence logo" className="company-logo" />
            <div className="tagline-container">
                <h3 className="ai-text">Powered by AI.</h3>
                <h3 className="people-text">Perfected by people.</h3>
            </div>
        </div>
    </div>
}

export default SplashLanding;