import Section from './Section';
import { useState, useRef } from 'react';

const LandingContactForm = props => {
    const [result, setResult] = useState('');
    const contactFormRef = useRef(null);
    const errorMessage = 'We can\'t seem to send the email. Mind reaching out directly to actualintelligencetech@gmail.com?';

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
            );
    };

    const submitForm = () => {
        if (contactFormRef.current) {
            const contactForm = contactFormRef.current;
            const formData = new FormData(contactForm);
            const name = formData.get('name');
            const email = formData.get('email');
            const message = formData.get('message');
            const emailIsValid = validateEmail(email);

            if (name && email && message && emailIsValid) {
                formData.append("access_key", "54fa2808-cdf7-4030-94e4-092a6456b843");
                setResult("Sending....");
                postForms(formData);
            } else if (email && !emailIsValid) {
                setResult("Would you mind checking your email? Something's doesn't seem right.");
            } else {
                setResult('Would you mind filling out all of the fields?');
            }
        }
    }

    async function postForms(formData) {
        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        }).then((res) => res.json());

        if (res.success) {
            console.log("Success", res);
            setResult('Great. Our team will get back with you shortly.');
        } else {
            console.log("Error", res);
            setResult(errorMessage);
        }
    }

    return <Section classLabel="contact-form section__dark container__centered">
        <div>
            <h2>Work with us</h2>
            <form ref={contactFormRef} onSubmit={event => event.preventDefault()}>
                <div>
                    <div className="form-group">
                        <input type="text" id="name" name="name" required />
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-group">
                        <input type="text" id="email" name="email" required />
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
                <div className="form-group">
                    <textarea id="message" name="message" required></textarea>
                    <label htmlFor="message">Message*</label>
                </div>
            </form>
            <button onClick={submitForm}><span>Send Message</span></button>
            <br />
            <br />
            {result}
        </div>
    </Section>
}

export default LandingContactForm;