import React, { useState, useRef } from 'react';
import Section from './Section';
import loader from '../../assets/loading.gif';
import pose1 from '../../assets/pose1.png';
import pose2 from '../../assets/pose2.png';
import pose3 from '../../assets/pose3.png';
import pose4 from '../../assets/pose4.png';
import imageFiles from './ImageFiles.json';


const ImageGenerator = props => {
    const imageResultsRef = useRef(null);
    const searchForImageURL = (searchString) => {
        const matches = imageFiles.filter(fileURL => fileURL.match(searchString));
        if (matches.length) {
            const chosenIndex = Math.round(Math.random() * matches.length);
            return matches[chosenIndex];
        }

        return null;
    }

    const [inputData, setInputData] = useState({
        pose: 'pose1',
        hairColor: 'black',
        location: 'city',
        sex: 'female'
    });

    const preliminaryImageURL = searchForImageURL('pose1-black-city-female');
    const [imageURL, setImageURL] = useState(preliminaryImageURL);
    const [loaded, setLoaded] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setInputData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const triggerImageChange = () => {
        setLoaded(false)
        const imageName = `${inputData.pose}-${inputData.hairColor}-${inputData.location}-${inputData.sex}`;
        const imageURL = searchForImageURL(imageName);
        setImageURL(imageURL);
        if (imageResultsRef.current) {
            imageResultsRef.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    return <Section classLabel="image-generation-demo">
        <div className="container__flex">
            <div className="controls-column">
                <h2>Are you ready <br />for AI?</h2>
                <p>Try our custom-built, human-supervised image generation tool below.</p>
                <form>
                    <div>
                        <label htmlFor="hair-color">Hair Color:</label>
                        <select id="hair-color" onChange={handleChange} value={inputData.hairColor} name="hairColor">
                            <option value="black">Black</option>
                            <option value="blond">Blond</option>
                            <option value="gray">Gray</option>
                            <option value="blue">Blue</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="location">Location:</label>
                        <select id="location" onChange={handleChange} name="location" value={inputData.location}>
                            <option value="city">City</option>
                            <option value="desert">Desert</option>
                            <option value="glacier">Glacier</option>
                            <option value="rainforest">Rainforest</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="sex">Sex:</label>
                        <select id="sex" onChange={handleChange} value={inputData.sex} name="sex">
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                        </select>
                    </div>
                    <div className="poses">
                        <label>Choose a Pose:</label>
                        <div className="poses-container">
                            <div className="pose-option">
                                <input type="radio" id="pose-1" name="pose" value="pose1" onChange={handleChange} checked={inputData.pose === 'pose1'} />
                                <label htmlFor="pose-1">
                                    <img src={pose1} alt="pose image 1" />
                                </label>
                            </div>
                            <div className="pose-option">
                                <input type="radio" id="pose-2" name="pose" value="pose2" onChange={handleChange} checked={inputData.pose === 'pose2'} />
                                <label htmlFor="pose-2">
                                    <img src={pose2} alt="pose image 2" />
                                </label>
                            </div>
                            <div className="pose-option">
                                <input type="radio" id="pose-3" name="pose" value="pose3" onChange={handleChange} checked={inputData.pose === 'pose3'} />
                                <label htmlFor="pose-3">
                                    <img src={pose3} alt="pose image 3" />
                                </label>
                            </div>
                            <div className="pose-option">
                                <input type="radio" id="pose-4" name="pose" value="pose4" onChange={handleChange} checked={inputData.pose === 'pose4'} />
                                <label htmlFor="pose-4">
                                    <img src={pose4} alt="pose image 4" />
                                </label>
                            </div>
                        </div>
                    </div>
                </form>
                <button onClick={triggerImageChange}>
                    <span>Generate image</span>
                </button>
            </div>
            <div className="image-results-column" ref={imageResultsRef}>
                {
                    imageURL && (
                        <img
                            src={`https://actualintelligence.tech/${imageURL}`}
                            onLoad={() => setLoaded(true)}
                            style={{ display: loaded ? 'block' : 'none' }}
                            alt="resulting image from generator"
                            className="resulting-image" />
                    )
                }
                {!loaded && (
                    <div className="loader-image">
                        Loading image
                        <br />
                        <img
                            src={loader}
                            width="100px"
                            alt="loading image" />
                    </div>
                )}
            </div>
        </div>
    </Section>
}

export default ImageGenerator;